import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useRecoilState } from 'recoil'
import { MdLanguage } from 'react-icons/md'
import format from 'date-fns/format'

import { Flex, Button, Stack, Divider, Text, Box } from '@chakra-ui/react'

import {
  Footer,
  FooterBody,
  FooterColumn,
  FooterDivider,
  FooterBottom,
  Main,
  P,
  BookingModal,
  WhatsappButton,
  Modal,
  H1,
  H2,
  Header,
  NavLink,
  FloatingBanner,
} from '../ui'

import SocialLinks from './socialLinks'
import FormQuote from './formQuote'

import { bookingModalState } from '../store'
import SEO from './seo'
import RequestForm from './requestForm'

import logoFooter from '../images/logo.jpg'
import logo from '../images/logo.jpg'
import PopupContent from './popupContent'

const globalStyles = css`
  @font-face {
    font-family: Open Sans;
    font-style: normal;
    font-display: swap;
    font-weight: 300;
  }

  @font-face {
    font-family: Open Sans;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: Open Sans;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: Open Sans, Arial, Helvetica, sans-serif !important;
    font-weight: 300;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
    opacity: 0.85;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }
`

function Layout({
  children,
  title,
  variantHeader,
  disableHeader,
  disableWhatsapp,
  disablePopup,
  disableFloatingBanner,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()
  const [bookingModal, setBookingModal] = useRecoilState(bookingModalState)

  useEffect(() => {
    let timer = setTimeout(() => setIsOpen(true), 30000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const onSubmit = async (values, setSuccess) => {
    const body = {
      ...values,
      checkIn: format(values.checkIn, 'MMMM, dd, yyyy'),
      checkOut: format(values.checkOut, 'MMMM, dd, yyyy'),
      to: 'hola@mayansecret.com.mx',
    }

    const res = await fetch(`${process.env.GATSBY_FORM_API}/api/reservation`, {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: JSON.stringify(body),
    })

    if (res.ok) setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Box>
      <Global styles={globalStyles} />
      <SEO title={`${title}`} />

      {!disableHeader && (
        <Header
          height="auto"
          logo={
            <Link to="/">
              <Flex alignItems="center" height={{ base: '25px', sm: '40px' }}>
                {/*<img style={{ height: '100%', width: 'auto' }} src={logo} />*/}
                <Text fontWeight="bold">Calculadora Legal</Text>
              </Flex>
            </Link>
          }
          position="absolute"
          variantHeader={variantHeader}
          backgroundColor="transparent"
          style={{ backdropFilter: 'blur(0px)' }}
        />
      )}
      <Flex direction="column" minHeight="100vh">
        <Main>
          {children}
          <Divider marginY={4} />
          <Flex
            marginBottom={{ base: '180px', lg: '200px', xl: 10 }}
            paddingX={4}
            alignItems="center"
            justifyContent="space-between"
            direction={{ base: 'column', md: 'row' }}
          >
            <Link to="/">
              <Text fontWeight="bold">Calculadora Legal - México 2021</Text>
            </Link>
            <Button as="a" href="mailto:info.calculadoralegal@gmail.com" textAlign="right">
              Envíanos un correo
            </Button>
          </Flex>
        </Main>
      </Flex>

      <Modal isOpen={isOpen} wrapperStyle={{ backgroundColor: 'transparent' }}>
        <PopupContent
          onClose={() => setIsOpen(false)}
          paddingTop={14}
          backgroundColor="white"
          position={{ base: 'absolute', lg: 'relative' }}
          top={{ base: 0, lg: 'initial' }}
          left={{ base: 0, lg: 'initial' }}
          maxWidth={{ base: '100%', lg: '860px' }}
          height={{ base: '100vh', lg: 'initial' }}
        />
      </Modal>

      {/*!disableWhatsapp && (
        <WhatsappButton
          bottom="65px"
          href={`https://api.whatsapp.com/send?phone=5219831000651&text=${t(
            'common:whatsappText'
          )}`}
        />
      )*/}

      {/*!disablePopup && (
        <Popup>
          <ButtonBook
            width="100%"
            height="100%"
            promotion="lastminute"
            arrival="2021-02-15"
            departure="2021-02-16"
          >
            <img style={{ width: '100%' }} src={language === 'es' ? popupEs : popupEn} />
          </ButtonBook>
        </Popup>
      )*/}

      {/*<BookingModal
        isOpen={bookingModal.isOpen}
        onClose={() => setBookingModal((obj) => ({ ...obj, isOpen: false }))}
        url={`https://rbe.zaviaerp.com/${bookingModal.promotion}?hotel=casacoyote&arrival=${
          bookingModal.arrival
        }&departure=${bookingModal.departure}&lng=${language === 'es' ? 'es' : 'en'}&currency=${
          language === 'es' ? 'mxn' : 'usd'
        }`}
      />*/}
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableWhatsapp: PropTypes.bool,
  disablePopup: PropTypes.bool,
  disableFloatingBanner: PropTypes.bool,
  title: PropTypes.string,
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Layout.defaultProps = {
  disableHeader: false,
  disableWhatsapp: false,
  disablePopup: false,
  disableFloatingBanner: false,
  title: '',
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 680, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment bannerSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 1270, quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 680, quality: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout
