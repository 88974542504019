import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from '@emotion/styled'
import { FaFacebook, FaInstagram, FaTripadvisor } from 'react-icons/fa'
import { Stack, IconButton, Button } from '@chakra-ui/react'

import { P } from '../ui'

const A = styled.a`
  font-size: inherit;
  text-decoration: underline;
`

const SocialLink = ({ icon, to }) => (
  <IconButton
    as="a"
    href={to}
    target="_blank"
    variant="outline"
    colorScheme="primary"
    _hover={{ background: 'transparent' }}
    icon={icon}
  />
)

function SocialLinks(props) {
  const { t } = useTranslation()

  return (
    <Stack spacing={2} {...props}>
      <P>
        Email: <A href="mailto:hola@mayansecret.com.mx">hola@mayansecret.com.mx</A>
      </P>
      <P>Tel: +52 1 (983) 100 0651</P>
      <P>
        Carretera Chetumal - Bacalar Km. 9.5, 77900, Q. Roo
        <Button
          as="a"
          colorScheme="primary"
          size="sm"
          variant="ghost"
          href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15132.718803922353!2d-88.3789479!3d18.5207798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ea31b61e2a59f9a!2sMayan%20Secret%20Hotel%20Boutique!5e0!3m2!1sen!2smx!4v1616467605451!5m2!1sen!2smx"
          target="_blank"
          _hover={{ background: 'transparent' }}
        >
          {t('common:viewMap')}
        </Button>
      </P>
      <Stack isInline shouldWrapChildren>
        <SocialLink to="https://instagram.com/mayansecretmx" icon={<FaInstagram />} />
        <SocialLink to="http://www.facebook.com/mayansecrethotelboutique" icon={<FaFacebook />} />
        <SocialLink
          to="https://www.tripadvisor.com/Hotel_Review-g317139-d5294258-Reviews-Mayan_Secret_Hotel_Boutique-Chetumal_Yucatan_Peninsula.html?m=19905"
          icon={<FaTripadvisor />}
        />
      </Stack>
    </Stack>
  )
}

export default SocialLinks
