import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Box, Stack } from '@chakra-ui/react'

import { flexPositions } from '../utils'

function Nav({ children, ...restProps }) {
  return (
    <Stack
      as="nav"
      isInline
      spacing={4}
      alignItems="center"
      overflowX="auto"
      height="100%"
      flex={{ base: '0 0 100%', md: 'auto' }}
      {...restProps}
    >
      {children}
    </Stack>
  )
}

export function NavLink({ children, ...restProps }) {
  return (
    <Box
      opacity="0.85"
      padding="10px 0"
      display="block"
      cursor="pointer"
      letterSpacing="0.85px"
      whiteSpace="nowrap"
      _hover={{ opacity: 1 }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

function Header({ variantHeader, logo, rightButton, navPosition, children, ...restProps }) {
  return (
    <Flex
      as="header"
      justify="space-between"
      width="100%"
      minHeight={{ base: 'auto', lg: '65px' }}
      paddingX={{ base: 6, lg: 20 }}
      paddingTop={{ base: 6, lg: 0 }}
      paddingBottom={{ base: 0, md: 3, lg: 0 }}
      alignItems="center"
      flexWrap="wrap"
      zIndex={2}
      position={{ base: 'relative', md: variantHeader === 'solid' ? 'initial' : 'fixed' }}
      style={{ backdropFilter: 'blur(4px)' }}
      {...restProps}
    >
      {logo}
      {rightButton && (
        <Box marginLeft={4} order={{ base: 0, md: 1 }}>
          {rightButton}
        </Box>
      )}
      <Nav
        order={{ base: 1, md: 0 }}
        justify={{ base: 'flex-start', md: flexPositions[navPosition] }}
      >
        {children}
      </Nav>
    </Flex>
  )
}

Header.propTypes = {
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
  navPosition: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  rightButton: PropTypes.node,
}

Header.defaultProps = {
  logo: null,
  rightButton: null,
  navPosition: 'right',
  variantHeader: 'solid',
}

export default Header
