import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Input, Stack } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { P } from '../ui'

function RequestForm({ children, arrival, color, departure, promotion, ...restProps }) {
  const { t } = useTranslation()

  return (
    <Stack spacing={1}>
      <P fontSize="xs" fontWeight="bold" opacity={0.75} color={color}>
        SÉ DE LOS PRIMEROS EN ENTERARTE:
      </P>
      <Flex>
        <Input backgroundColor="white" placeholder="correo@ejemplo.com" borderRightRadius="0" />
        <Button colorScheme="orange" flexShrink="0" borderLeftRadius="0">
          Suscribirme
        </Button>
      </Flex>
      <P color={color} fontSize="sm">
        3 usuarios se han suscrito
      </P>
    </Stack>
  )
}

RequestForm.propTypes = {
  children: PropTypes.node,
  color: 'inherit',
  colorScheme: PropTypes.string,
  arrival: PropTypes.string,
  departure: PropTypes.string,
  promotion: PropTypes.string,
}

RequestForm.defaultProps = {
  children: null,
  colorScheme: 'primary',
  arrival: '',
  departure: '',
  promotion: '',
}

export default RequestForm
