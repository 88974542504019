import React from 'react'

import { List, ListIcon, ListItem, Divider, IconButton } from '@chakra-ui/react'
import { AiFillCheckCircle, AiOutlineClose } from 'react-icons/ai'

import { P, Section } from '../ui'

import WhatsappButton from '../components/whatsappButton'

function PopupContent({ counter, onClose, disableCloseButton, ...restProps }) {
  return (
    <Section
      maxWidth="800px"
      marginX="auto"
      title="¡Evita la preclusión de tu derecho o la de tu firma!"
      subtitle="Envíanos un mensaje de Whatsapp para notificarte cuando lancemos la aplicación."
      titleAlign="center"
      boxShadow="0px 0px 10px rgba(0,0,0,0.25)"
      {...restProps}
    >
      <Divider />
      <List spacing={3}>
        <ListItem display="flex" alignItems="center" justifyContent="center">
          <ListIcon fontSize="1.5rem" as={AiFillCheckCircle} color="green.500" />
          <P fontSize="lg" width="fit-content">
            Recibe 1 mes gratis al lanzamiento
          </P>
        </ListItem>
        <ListItem display="flex" alignItems="center" justifyContent="center">
          <ListIcon fontSize="1.5rem" as={AiFillCheckCircle} color="green.500" />
          <P fontSize="lg" width="fit-content">
            Sé de los primeros en usar esta nueva tecnología
          </P>
        </ListItem>
        <ListItem display="flex" alignItems="center" justifyContent="center">
          <ListIcon fontSize="1.5rem" as={AiFillCheckCircle} color="green.500" />
          <P fontSize="lg" width="fit-content">
            Podrás influenciar en el desarrollo de la app
          </P>
        </ListItem>
      </List>
      <Divider />
      <WhatsappButton counter={counter} />
      <Divider />
      {!disableCloseButton && (
        <IconButton
          onClick={onClose}
          position="absolute"
          top={0}
          right={0}
          width="fit-content"
          variant="ghost"
          aria-label="Search database"
          icon={<AiOutlineClose size="1.85rem" />}
        />
      )}
    </Section>
  )
}

export default PopupContent
