import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Stack, Button } from '@chakra-ui/react'
import { ImWhatsapp } from 'react-icons/im'
import { useRecoilState } from 'recoil'

import { P } from '../ui'
import { counterState } from '../store'

function WhatsappButton() {
  const [counter, setCounter] = useRecoilState(counterState)

  useEffect(() => {
    const fetchData = async () => {
      fetch('https://ina5ocxm0f.execute-api.us-east-1.amazonaws.com/dev/counter')
        .then((res) => res.json())
        .then(
          (result) => {
            setCounter(result)
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            })
          }
        )
    }

    fetchData()
  }, [])

  const onCounter = () => {
    const fetchData = async () => {
      fetch('https://ina5ocxm0f.execute-api.us-east-1.amazonaws.com/dev/counter', {
        method: 'PUT',
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setCounter(result)
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            })
          }
        )
    }

    fetchData()
  }

  return (
    <Stack spacing={2} padding={{ base: 2, md: 4 }} textAlign="center" marginX="auto" width="100%">
      <P fontSize="sm" fontWeight="bold" opacity={0.75}>
        ¡SÉ DE LOS PRIMEROS! Y RECIBE 1 MES GRATIS AL LANZAMIENTO
      </P>
      <Button
        onClick={() => onCounter()}
        as="a"
        href="https://api.whatsapp.com/send?phone=5215554520350&text=Hola, quiero suscribirme a la lista de espera de la aplicación Calculadora Legal..."
        size="lg"
        colorScheme="green"
        leftIcon={<ImWhatsapp size="1.5rem" />}
      >
        Quiero más información
      </Button>
      <P fontSize="sm" opacity={0.75}>
        {counter} personas han pedido más información.
      </P>
    </Stack>
  )
}

WhatsappButton.propTypes = {
  counter: PropTypes.number,
  onClick: PropTypes.func,
}

export default WhatsappButton
